import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import Helmet from "react-helmet"
import { Router } from "@reach/router"
import InViewObserver from "../components/InViewObserver"
import PageContentHeadSwitch from "../components/PageCententHeadSwitch"

import Link from "../components/LinkWithTransitionLock";
import Page_Footer from './page_footer'
// import Scrollbar from 'smooth-scrollbar';

import { TimelineMax, TweenMax, TweenLite, Linear, TextPlugin, ThrowPropsPlugin, EasePack, CSSPlugin, ScrollToPlugin, Power1, Power2, Power4, Expo, gsap } from 'gsap';

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {
    
    InViewObserver()
    
  }
 
  componentWillUnmount() {

  }


  render() {

    return (
      // <>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">沈伯丞 x 林立青
            <PageContentHeadSwitch/>
          </h2>
          <h1 className="main_title">台北萬華 最美的是人</h1>
        </div>

        <div className="page_content">

          <section className="row_area_s project_a_muse_a">
            <div className="column_3">
              <div className="context">
                {/* <h6>林立青</h6> */}
                <h6>策展人的話</h6>
                <p>閱讀了林立青的著作：做工的人，他的筆讓我們的眼光落向了這城市被遺忘、忽略的角落和人生。也正是立青的文章讓我產生了共鳴，公共（public）應該是公眾的記憶集合體，而公共藝術應該是 <b>left no one behind</b> 的關懷。也因此，在這個討論公共藝術的可能性的計劃中，我想立青的筆將會帶著我們看見城市那不起眼確又閃爍著希望的光和溫暖。</p>
                <p className="tag">拍攝剪輯/高凌雲</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_a_muse_b row_gallery">
            <div className="column_4 gallery_wrap">
              <figure className="figure project_item video_item size_full gallery_animate">
                <Link to='/project_a_muse_1' className="project_cover">
                  <img src={require("../images/project_a_muse_1-2.jpg")} alt="計畫對談"/>
                </Link>
                <Link to='/project_a_muse_1' className="project_item_title">
                  <h4>計畫對談</h4>
                  <h6>策展人沈伯丞＆作家林立青</h6>
                </Link>
              </figure>
            </div>
            <div className="column_3 gallery_wrap">
              <figure className="figure project_item video_item size_full gallery_animate">
                <Link to='/project_a_muse_2' className="project_cover">
                  <img src={require("../images/project_a_muse_2-2.jpg")} alt="八坪大 歐陽如修"/>
                </Link>
                <Link to='/project_a_muse_2' className="project_item_title">
                  <h4>八坪大</h4>
                  <h6>歐陽如修</h6>
                </Link>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_a_muse_c row_gallery">
            <div className="column_3 onset_left_1 gallery_wrap">
              <figure className="figure project_item video_item size_full gallery_animate">
                <Link to='/project_a_muse_3' className="project_cover">
                  <img src={require("../images/project_a_muse_3.jpg")} alt="柳隅茶社 藍子鈞"/>
                </Link>
                <Link to='/project_a_muse_3' className="project_item_title">
                  <h4>柳隅茶社</h4>
                  <h6>藍子鈞</h6>
                </Link>
              </figure>
            </div>
            <div className="column_3 onset_left_1 gallery_wrap">
              <figure className="figure project_item video_item size_full gallery_animate">
                <Link to='/project_a_muse_4' className="project_cover">
                  <img src={require("../images/project_a_muse_4.jpg")} alt="八海火鍋 賴珮立"/>
                </Link>
                <Link to='/project_a_muse_4' className="project_item_title">
                  <h4>八海火鍋</h4>
                  <h6>賴珮立</h6>
                </Link>
              </figure>
            </div>
          </section>

          <section className="row_area_s project_a_muse_d row_gallery">
            <div className="column_3 gallery_wrap">
              <figure className="figure project_item video_item size_full gallery_animate">
                <Link to='/project_a_muse_5' className="project_cover">
                  <img src={require("../images/project_a_muse_5-2.jpg")} alt="禾順商旅 徐舒涵"/>
                </Link>
                <Link to='/project_a_muse_5' className="project_item_title">
                  <h4>禾順商旅</h4>
                  <h6>徐舒涵</h6>
                </Link>
              </figure>
            </div>
            <div className="column_3 gallery_wrap">
              <figure className="figure project_item video_item size_full gallery_animate">
                <Link to='/project_a_muse_6' className="project_cover">
                  <img src={require("../images/project_a_muse_6.jpg")} alt="福大同"/>
                </Link>
                <Link to='/project_a_muse_6' className="project_item_title">
                  <h4>福大同</h4>
                  <h6>蔡子晴</h6>
                </Link>
              </figure>
            </div>
          </section>

        </div>

        <div className="head_intro_content headroom">
          <h5 className="name">林立青</h5>
          <p className="bio">本名林亞靖，1985 年生，東南科技大學土木工程系進修部畢業，從事工地現場監工10年，亦擔任專欄作家，有文字著作「做工的人」、「如此人生」等書。</p>
        </div>

        <div className="page_footer">
          <Link to="/project_a_gift" className="next_page">
            <div className="next_title">《魏絲貝斯計畫》</div>
            <div className="next_owner">何孟娟</div>
          </Link>
        </div>

      </div>

      // </>
    )

  }

}


export default Page

